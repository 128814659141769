import { BaseElement, html, css } from 'Elements';
import { Fetcher, UrlParams, Lang, ProcessNextTick } from 'Utils';

import { VALIDATION_STATUS_ICON } from './config.js';
import nodeTypes from './types.js';

class SelectRegistry extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          font-family: Calibri;
        }

        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .search sl-input {
          width:100%;
        }

        .search sl-button {
          width:40px;
          height:30px;
        }

        .tree_item_img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 4px;
          left: 0px;
        }

        .icons_right {
          position: absolute;
          right:0px;
          width:35vw;
          display:flex;
          /*justify-content:end;*/
          align-items:center;
          box-sizing:border-box;
        }

        .validation_icons {
          display:flex;
          align-items:center;
          min-width:22px;
          width:22px;
          white-space:nowrap;
          padding:2px;
          gap:2px;
          min-height:20px;
          margin-left:-1px;
          border-right: 1px solid var(--sl-color-neutral-300);
          border-left: 1px solid var(--sl-color-neutral-300);
          /*outline:1px solid var(--sl-color-neutral-300);*/
          font-size:1.3em;
          /*justify-content:center;*/
          transition:width 0.3s, min-width 0.3s;
          overflow:hidden;
        }

        .validation_icons.full {
          min-width:56px;
          width:56px;
        }

        .validation_icons.two {
          display:flex;
          align-items:center;
          min-width:44px;
          width:44px;
          white-space:nowrap;
          padding:2px;
          gap:2px;
          min-height:20px;
          margin-left:-1px;
          border-right: 1px solid var(--sl-color-neutral-300);
          border-left: 1px solid var(--sl-color-neutral-300);
          /*outline:1px solid var(--sl-color-neutral-300);*/
          font-size:1.3em;
          /*justify-content:center;*/
          transition:width 0.3s, min-width 0.3s;
          overflow:hidden;
        }

        .validation_icons.two.full {
          min-width:112px;
          width:112px;
        }

        sl-tree {
          /*background-color: var(--sl-color-neutral-100);*/
        }

        sl-tree-item {
          --indent-size:15px;
          --indent-guide-width:0px;
        }

        sl-tree-item::part(label) {
          font-family:Calibri;
          font-size:1rem;
          color:var(--text-color);
          width:100%;
          padding-left:20px;
          position:relative;
          line-height:1.5em;
          display: flex;
          justify-content: stretch;
        }

        sl-tree-item span {
          display:block;
          white-space:nowrap;
        }

        sl-tree-item::part(item) {
          font-family:Calibri;
          color:var(--text-color);
          width:100%;
          padding-left:0px;
          position:relative;
          border-bottom:1px solid var(--sl-color-neutral-300);
        }

        sl-tree-item::part(expand-button) {
          padding: 3px;
          padding-right: 7px;
          top: 3px;
          position: relative;
        }

        sl-tree-item .icons_left m-icon {
          font-size:18px;
          padding-left:4px;
          padding-right:4px;
          padding-top:2px;
          position:absolute;
          left:-5px;
          top:1px;
        }

        sl-tree-item.organization::part(label) {
          font-weight:bold;
        }

        sl-tree-item.affiliate::part(label) {
          font-weight:bold;
        }

        sl-tree-item.entity::part(label) {
          font-weight:bold;
        }

        sl-tree-item.disabled::part(label) {
          color:var(--sl-color-neutral-400);
        }

        sl-tree-item.trash::part(label) {
          color:var(--sl-color-danger-600);
        }

        sl-tree-item.disabled .icons_left m-icon {
          color:var(--sl-color-neutral-400);
        }

        sl-tree-item .node_title {
          min-width:200px;
        }

        sl-tree-item .node_title:hover {
          color:var(--sl-color-primary-500);
        }

        sl-tree-item.invalid::part(label) {
          color:red;
          text-decoration: line-through;
        }

        sl-tree-item .tree_menu {
          position:absolute;
          right:10px;
          padding-top:2px;
          max-height:15px;
          max-width:15px;
        }

        /*
        sl-tree-item.hide-during-drag {
          --indent-guide-width:0px;
          --indent-size:0px;
          --indent-guide-offset:0px;
        }

        sl-tree-item.hide-during-drag::part(indentation) {
          opacity:0;
        }

        sl-tree-item.hide-during-drag::part(expand-button) {
          display:none;
        }

        sl-tree-item.hide-during-drag::part(checkbox) {
          display:none;
        }
        */

        .bold::part(label) {
          /*font-weight:bold;*/
        }

        sl-tree-item.drag-over {
          outline:1px solid red;
        }

        .toolbar {
          display:flex;
          justify-content:space-between;
          align-items:center;
          margin-top:2px;
        }

        .toolbar > * {
          width:100%;
          display:flex;
          gap:10px;
          justify-content:flex-end;
        }

        .toolbar sl-select {
          width:300px;
        }

        .toolbar sl-option m-icon {
          font-size:1em;
        }

        sl-menu-item {
          padding: 0;
          cursor: pointer;
          font-size:0.2em;
        }

        sl-menu-item::part(checked-icon) {
          display:none;
        }

        sl-menu-item::part(label) {
          padding:0px;
          font-size:0.9em;
          font-family: Calibri;
          line-height:initial;
        }

        .hidden {
          display:none;
        }

        .hidden_visibility: {
          visibility:hidden;
        }

        .gray::part(label) {
          opacity:0.6;
        }

        mark {
          background-color: yellow;
        }

        sl-radio-group::part(form-control-label) {
          font-size:1em;
        }

        .hidden_label m-icon {
          padding-top:1px;
          padding-right:0px !important;
        }

        .hidden_label::part(base) {
          font-size: 0.9em;
          height: calc(var(--sl-input-height-small)* 0.6);
          line-height: initial;
          padding: 0 2px;
          font-weight:normal;
        }

        .hidden_label div {
          max-width: 0;
          /*max-width: 150px;*/
          overflow: hidden;
          white-space: nowrap;
          transition: max-width 0.3s;
        }

        .hidden_label:hover div {
          max-width: 200px;
          padding-left: 5px;
          padding-right: 5px;
        }

        modal-dialog m-icon {
          font-size:1.6em;
        }

        .flex {
          display: flex;
          align-items: center;
          width: 100%;
          gap:20px;
          justify-content: space-around;
        }

        .flex box-styled {
          width: 100%;
        }

        .validation {
          opacity:1;
        }

      `
    ];
  }

  static get properties() {
    return {
      displayOptions: { type: Array },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            expandAll:'Expand all',
            collapseAll:'Collapse all',
            expand:'Expand',
            collapse:'Collapse',
            add:'Add',
            remove:'Delete',
            archive:'Archive',
            edit:'Edit',
            display:{
              title:'Display ...',
              roles:'Roles',
              permissions:'Permissions',
              validations_email:'Email validations',
              validations_mobile:'Mobile validations',
            },
            validation_required:{
              enable:{
                email:'Email validation enabled',
                mobile:'Mobile validation enabled',
              },
              disable:{
                email:'Email validation disabled',
                mobile:'Mobile validation disabled',
              },
            },
            validation_email_primary_status:{
              waiting:'{{text}}: primary email address waiting for validation',
              validated:'{{text}}: primary email address validated',
              not_validated:'{{text}}: primary email address not validated',
              error:'{{text}}: an error occurred while sending the validation',
              undefined:'No primary email address defined',
            },
            validation_email_secondary_status:{
              waiting:'{{text}}: secondary email address waiting for validation',
              validated:'{{text}}: secondary email address validated',
              not_validated:'{{text}}: secondary email address not validated',
              error:'{{text}}: an error occurred while sending the validation',
              undefined:'No secondary email address defined'
            },
            validation_mobile_primary_status:{
              waiting:'{{text}}: primary mobile waiting for validation',
              validated:'{{text}}: primary mobile validated',
              not_validated:'{{text}}: primary mobile not validated',
              error:'{{text}}: an error occurred while sending the validation',
              undefined:'No primary mobile defined',
            },
            validation_mobile_secondary_status:{
              waiting:'{{text}}: secondary mobile waiting for validation',
              validated:'{{text}}: secondary mobile validated',
              not_validated:'{{text}}: secondary mobile not validated',
              error:'{{text}}: an error occurred while sending the validation',
              undefined:'No secondary mobile defined',
            }
          },
        },
        french:{
          translation: {
            expandAll:'Tout déplier',
            collapseAll:'Tout replier',
            expand:'Déplier',
            collapse:'Replier',
            add:'Ajouter',
            archive:'Archiver',
            remove:'Supprimer',
            edit:'Editer',
            display:{
              title:'Afficher ...',
              roles:'Rôles',
              permissions:'Permissions',
              validations_email:'Validation des emails',
              validations_mobile:'Validation des mobiles',
            },
            validation_required:{
              enable:{
                email:'Validation email activée',
                mobile:'Validation mobile activée',
              },
              disable:{
                email:'Validation email désactivée',
                mobile:'Validation mobile désactivée',
              },
            },
            validation_email_primary_status:{
              waiting:'{{text}}: adresse email principale en attente de validation',
              validated:'{{text}}: adresse email principale validée',
              not_validated:'{{text}}: adresse email principale non validée',
              error:'{{text}}: une erreur est survenue lors de l\'envoie de la validation',
              undefined:'Aucune adresse email principale définie',
            },
            validation_email_secondary_status:{
              waiting:'{{text}}: adresse email secondaire en attente de validation',
              validated:'{{text}}: adresse email secondaire validée',
              not_validated:'{{text}}: adresse email secondaire non validée',
              error:'{{text}}: une erreur est survenue lors de l\'envoie de la validation',
              undefined:'Aucune adresse email secondaire définie'
            },
            validation_mobile_primary_status:{
              waiting:'{{text}}: mobile principal en attente de validation',
              validated:'{{text}}: mobile principal validé',
              not_validated:'{{text}}: mobile principal non validé',
              error:'{{text}}: une erreur est survenue lors de l\'envoie de la validation',
              undefined:'Aucun mobile principal défini'
            },
            validation_mobile_secondary_status:{
              waiting:'{{text}}: mobile secondaire en attente de validation',
              validated:'{{text}}: mobile secondaire validé',
              not_validated:'{{text}}: mobile secondaire non validé',
              error:'{{text}}: une erreur est survenue lors de l\'envoie de la validation',
              undefined:'Aucun mobile secondaire défini'
            }
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.apiEndpoint = 'private/admin/registry/tree';
    this.apiEndpointGrp = 'private/admin/registry/grp';
    this.selectedItem = null;
    this.selectedTreeItem = null;
    this.hasRoot = false;
    this.nodeTypes = null;
    this.onKeycloakBrowserDragStart = this.onKeycloakBrowserDragStart.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.doItemMove = this.doItemMove.bind(this);
    this.eventUpdated = 'registry-updated';
    this.firstLoad = true;
    this.displayOptions = ['validations_email', 'validations_mobile'];
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener('keycloak-browser-drag-start', this.onKeycloakBrowserDragStart);
    window.addEventListener('language-changed', this.onLanguageChanged);
  }

  async disconnectedCallback() {
    super.disconnectedCallback();
    this.uninstallKeyboardHandler();
    this.uninstallNodeUpdateHandler();
    window.removeEventListener('keycloak-browser-drag-start', this.onKeycloakBrowserDragStart);
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  async firstUpdated() {
    super.firstUpdated();
    this.nodeTypes = nodeTypes.getNodeTypes();
    await this.refreshData();
    this.tree = this.qs('sl-tree');
    this.modalConfirmDelete = this.qs('#modal-registry-confirm-delete');
    this.modalConfirmArchive = this.qs('#modal-registry-confirm-archive');
    this.modalConfirmRestore = this.qs('#modal-registry-confirm-restore');
    this.modalConfirmMove = this.qs('#modal-registry-confirm-move');
    this.nodeEdit = this.parentNode.querySelector('registry-node-edit');
    this.installKeyboardHandler();
    this.installNodeUpdateHandler();
    this.expanded = [];
    const expNodes = localStorage.getItem('registryTreeExpandedNodes');
    if (expNodes) {
      this.expandedNodes = JSON.parse(expNodes);
      this.treeRestoreExpanded();
    }
  }

  async expandRootNode() {
    const root = this.shadowRoot.querySelector('sl-tree-item.root');
    if (root) root.expanded = true;
  }

  async onLanguageChanged() {
    this.nodeTypes = nodeTypes.getNodeTypes();
    this.requestUpdate();
  }

  async onKeycloakBrowserDragStart(ev) {
    this._log.debug('onKeycloakBrowserDragStart', ev.detail);
    this.floatingDiv = document.querySelector('#dragImage');
    this.kcBrowserDraggedItems = ev.detail;
  }

  async onKeyDown(ev) {  
    ev.stopPropagation();
    ev.preventDefault();

    if (ev.key === '+') {
      await this.treeCollapseToggle({ recursive:false, expand:true, root: this.selectedTreeItem || this.tree });
      await this.treeStoreExpanded();
    } else if (ev.key === '-') {
      await this.treeCollapseToggle({ recursive:false, expand:false, root: this.selectedTreeItem || this.tree });
      await this.treeStoreExpanded();
    } else if (ev.key === '*') {
      await this.treeCollapseToggle({ recursive:true, expand:!this.tree.expanded, root: this.tree });
      await this.treeStoreExpanded();
    } else if (ev.key === 'Delete') {
      //@FIXME: je suis dans la poubelle (delete) ou pas (archive ?)
      await this.showConfirmDelete(ev);
    }
  }
  
  async installKeyboardHandler() {
    this.onKeyDown = this.onKeyDown.bind(this);
    if (this.tree) {
      this.tree.addEventListener('keydown', this.onKeyDown);
    }
  }

  async uninstallKeyboardHandler() {
    if (this.tree) {
      this.tree.removeEventListener('keydown', this.onKeyDown);
    }
  }

  async installNodeUpdateHandler() {
    this.onNodeUpdated = this.onNodeUpdated.bind(this);
    window.addEventListener(this.eventUpdated, this.onNodeUpdated);
  }

  async uninstallNodeUpdateHandler() {
    window.removeEventListener(this.eventUpdated, this.onNodeUpdated);
  }

  async treeStoreExpanded() {
    if (!this.tree) return;
    this.expandedNodes = [];
    this.tree.querySelectorAll('sl-tree-item').forEach(treeItem => {
      if (treeItem.expanded) {
        this.expandedNodes.push(treeItem.item._id);
      }
    });

    // store expanded nodes in local storage
    localStorage.setItem('registryTreeExpandedNodes', JSON.stringify(this.expandedNodes));
  }

  async treeRestoreExpanded() {
    this.tree = this.shadowRoot.querySelector('sl-tree');
    if (!this.tree) return;
    this.tree.querySelectorAll('sl-tree-item').forEach(async treeItem => {
      treeItem.classList.remove('hidden');
      treeItem.classList.remove('gray');

      // remove <mark> tags
      const slot = treeItem.shadowRoot.querySelector('slot[part="label"]');
      if (slot) {
        const nodes = slot.assignedNodes({ flatten: true });
        nodes.forEach(node => {
          if (node.tagName === 'SPAN' && node.className === 'title') {
            node.innerHTML = node.textContent;
          }
        });
      }

      if (this.expandedNodes) {
        treeItem.expanded = this.expandedNodes.includes(treeItem.item._id);
        if (this.selectedItem && treeItem.item._id === this.selectedItem._id) {
          treeItem.selected = true;
          this.selectedTreeItem = treeItem;
          this.selectedItem = treeItem.item;
        }
      }
    });
  }

  async treeCollapseToggle( opts ) {
    opts.root.expanded = opts.expand;
    let items;
    if (opts.recursive) {
      items = opts.root.querySelectorAll('sl-tree-item');
    } else {
      items = opts.root.querySelectorAll(':scope > sl-tree-item');
    }
    for (const item of items) item.expanded = opts.expand;
  }

  async sendEvent(detail = {}) {
    const event = new CustomEvent(this.eventUpdated, { detail });  
    window.dispatchEvent(event);
  }

  async onNodeUpdated(ev) {
    this._log.debug('onNodeUpdated', ev.detail);
    this.treeStoreExpanded();

    if (ev.detail._id) {
      await this.refreshData();
      await this.updateComplete;
      const qs = `sl-tree-item[data-id="${ev.detail._id}"]`;
      setTimeout(() => {
        const item = this.qs(qs);
        if (item) {
          item.selected = true;
          item.expanded = true;
          this.selectedTreeItem = item;
          this.selectedItem = item.item;
          // recursively expand all parents
          let parent = item.parentNode;
          while (parent.tagName === 'SL-TREE-ITEM') {
            parent.expanded = true;
            parent = parent.parentNode;
          }

          this.treeStoreExpanded();
        }
      }, 100);
    } else {
      await this.refreshData();
    }

    this.treeRestoreExpanded();
  }

  async emptySelection() {
    if (this.selectedTreeItem) {
      this.selectedTreeItem.selected = false;
      this.selectedTreeItem = null;
      this.selectedItem = null;
    }
  }

  async reload() {
    await this.refreshData();
  }

  async refreshData() {
    this.loading = true;
    this.loader = this.loader || this.shadowRoot.querySelector('sl-progress-bar');
    this.loader.style.visibility = 'visible';
    const response = await Fetcher.get(this.apiEndpoint);
    if (!response) return;
    const items = response.data;

    // look for nodeType "root" and place it at the first position
    const rootIndex = items.findIndex(item => item.nodeType === 'root');
    if (rootIndex !== -1) {
      const root = items.splice(rootIndex, 1);
      items.unshift(root[0]);
      this.hasRoot = true;
    }

    this.items = items;
    this.loading = false;
    this.loader.style.visibility = 'hidden';
    this.firstLoad = false;
    this.requestUpdate();
  }

  renderTreeItemIcon(item) {
    if (item.favicon_url) {
      return html`<img src="${item.favicon_url}" class="tree_item_img">`;
    }

    const icon = this.nodeTypes[item.nodeType]?.micon || 'question_mark';

    if (icon) {
      return html`<m-icon name="${icon}"></m-icon>`;
    } else {
      this._log.warn('No icon for nodeType', item.nodeType);
      return '';
    }
  }

  isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node instanceof ShadowRoot ? node.host : node.parentNode;
    }
    return false;
  }

  onTreeSelectionChange(ev) {
    this.selectedTreeItem = ev.detail.selection[0];
    this.selectedItem = this.selectedTreeItem.item;
    if (!this.selectedTreeItem.expanded) {
      this.selectedTreeItem.expanded = true;
    }
    //this.updateCollapseButton();
  }

  getNodeTitle(item) {
    //this._log.debug('getNodeTitle', item);
    const nodeType = this.nodeTypes[item.nodeType];
    if (!nodeType) {
      // item.email is coming from this.kcBrowserDraggedItem
      return item.email || item.name || item.value || item._id;
    }

    const fields = nodeType.nodeTitle;
    if (!fields) {
      return item.name || item.value || nodeType.name;
    }

    let str = fields.map(field => {
      if (item[field]) return `${item[field]}`;
    });

    // remove undefined values
    str = str.filter(Boolean);
    str = str.join(' ');
    if (!str) {
      str = nodeType.name;
    }
    //str = this.sanitizeHTML(str);
    return str;
  }

  sanitizeHTML(str) {
    if (typeof str !== 'string') return str;
    return str.replace(/&/g, '&amp;')
              .replace(/</g, '&lt;')
              .replace(/>/g, '&gt;')
              .replace(/"/g, '&quot;')
              .replace(/'/g, '&#039;')
              .replace(/`/g, '&#x60;');
  }

  searchOnInput(ev) {
    const target = ev.target;
    clearTimeout(this.searchTimeout);   
    this.searchTimeout = setTimeout(() => {
      this.q = target.value.toLowerCase().trim();

      if (!this.q) {
        this.treeRestoreExpanded();
        return;
      }
      
      if (!this.tree) return;

      const items = this.tree.querySelectorAll('sl-tree-item');
      if (!items) return;

      items.forEach(treeItem => {
        let found = false;
        
        //console.log('analyze', treeItem.item.name || treeItem.item.firstname);

        // loop trought all item attribut values to find a match
        for (const value of Object.values(treeItem.item)) {
          //console.log('==> look', this.q,'in', value);
          if (value && value.toString().toLowerCase().indexOf(this.q) >= 0) {
            //console.log('==> found', this.q,'in', treeItem);
            found = true;
            break;
          }
        }

        if (found) {
          // visible
          treeItem.classList.remove('hidden');
          treeItem.classList.remove('gray');
          // highlight matching part
          const slot = treeItem.shadowRoot.querySelector('slot[part="label"]');
          if (slot) {
            const nodes = slot.assignedNodes({ flatten: true });
            nodes.forEach(node => {
              if (node.tagName === 'SPAN' && node.className === 'title') {
                const originalText = node.textContent;
                const regex = new RegExp(`(${this.q})`, 'gi');
                const highlightedText = originalText.replace(regex, '<mark>$1</mark>');
                node.innerHTML = highlightedText;
              }
            });
          }

          while (treeItem.parentElement) {
            treeItem = treeItem.parentElement;
            treeItem.expanded = true;
            treeItem.classList.remove('hidden');
            treeItem.classList.add('gray');
          }
        } else {
          //console.log('=> hide');
          treeItem.classList.add('hidden');
        }

      });
    }, 500);

  }

  showModalUserValidation(ev, item) {
    ev.stopPropagation();
    ev.preventDefault();
    this.modalContactValidation = this.modalContactValidation || this.qs('registry-node-contact-validation');
    this.modalContactValidation.item = null;
    this.modalContactValidation.item = item;
  }

  itemIsDisabled(item) {
    return ((typeof item.enabled === 'boolean' && !item.enabled) || item.in_trash || item._merged.iora_roles?.includes('-'));
  }

  renderIconsValidationStatus(item, device, icons) {
    const showValidationEmail = this.displayOptions?.includes(`validations_${device}`);
    if (!showValidationEmail) return;

    const validationIcons = [];

    if (this.nodeTypes[item.nodeType].validationSetup) {
      let disabled = this.itemIsDisabled(item) ? 'disabled' : '';
      if (!disabled) {
        for (const type of ['primary', 'secondary']) {
          let title, value, validationStatus, iconCss;
          value = item[`${device}_${type}`];
          if (!value) {
            // no email or mobile phone defined
            iconCss = 'disabled';
            validationStatus = 'undefined';
          } else {
            validationStatus = item[`validation_${device}_${type}_status`] || 'not_validated';
          }
          title = this._tl(`validation_${device}_${type}_status.${validationStatus}`, { text: item[`${device}_${type}`] });
          const icon = VALIDATION_STATUS_ICON[device][validationStatus];
          
          validationIcons.push(html`
            <sl-tooltip style="--max-width: 80vw;--show-delay:300;" content="${title}">
              <m-icon name="${icon}" class="validation ${validationStatus} ${iconCss}"></m-icon>
            </sl-tooltip>
          `);
        }
        icons.push(html`<span class="validation_icons two" @click="${ev => this.showModalUserValidation(ev, item)}">${validationIcons}</span>`);
      }
    } else {
      // empty column
      icons.push(html`<span class="validation_icons two"></span>`);
    }
  }

  renderIconsEdr(item, icons) {
    //const showValidationEmail = this.displayOptions?.includes(`edr_status`);
    //if (!showValidationEmail) return;
    if (item.nodeType.startsWith('edr')) {
      icons.push(html`<span class="validation_icons"><m-icon name="sentiment_neutral"></m-icon></span>`);
    }
  }

  renderIconsItem(item) {

    const icons = [];

    ///////////////////////////
    // VALIDATIONS
    ///////////////////////////

    this.renderIconsValidationStatus(item, 'email', icons);
    this.renderIconsValidationStatus(item, 'mobile', icons);
    this.renderIconsEdr(item, icons);

    ///////////////////////////
    // ROLES
    ///////////////////////////

    if (this.displayOptions?.includes('roles')) {
      if (item._merged) {
        this.selectIoRolesInstance = this.selectIoRolesInstance || document.createElement('select-io-roles');
        for (const [key, value] of Object.entries(item._merged)) {
          if (key === 'iora_roles') {
            if (!value) continue;
            const roleIcons = value.split(',').map(roleId => {
              const r = this.selectIoRolesInstance.items.find(r => r._id === roleId);
              return html`
                <sl-tag variant="${r.variant}" size="small" class="hidden_label">
                  <m-icon name="${r.icon}" style="padding-right:4px"></m-icon>
                  <div>${Lang.lookup(r, 'name')}</div>
                </sl-tag>
              `;
            });
            icons.push(...roleIcons);
          }
        }
      }
    }

    return icons;
  }

  renderLoading() {
    this._log.debug('renderLoading');
    return html`<sl-progress-bar style="--height: 2px;" indeterminate></sl-progress-bar>`;
  }

  isNodeDisabled(item) {
    if (item.enabled === false) return true;
    if (item._merged.iora_roles?.includes('-')) return true;
  }

  renderTreeItems(item, items) {
    const childrens = items.filter(child => child.parentId === item._id);

    // order by nodeType and name, nodeType having noChilds last
    childrens.sort((a, b) => {
      const nodeType = this.nodeTypes[a.nodeType];
      if (!nodeType) {
        console.warn('Unknown nodeType', a.nodeType);
        return -1;
      }
      if (nodeType.noChilds) {
        return 1;
      } else if (!nodeType.noChilds) {
        return -1;
      } else {
        if (a.nodeType === b.nodeType) {
          return this.getNodeTitle(a).localeCompare(this.getNodeTitle(b));
        }
      }
    });


    const nodeType = this.nodeTypes[item.nodeType];

    if (!nodeType) {
      return html`
        <sl-tree-item 
          data-id="${item._id}"
          data-name="${item.name}"
          .item="${item}"
          value="${this.getNodeTitle(item)}"
          class="invalid"
        >
          <span class="icons_left" title="${item.name} (${item.nodeType}) ${item.id}">${this.renderTreeItemIcon(item)}</span>
          <span class="node_title" title="${item.name} (${item.nodeType})" class="invalid">${this.getNodeTitle(item)}</span>
          <span class="icons_right">${this.renderIconsItem(item)}</span>
          <span class="tree_menu" @click="${ev => this.showTreeMenu(ev)}"><m-icon name="more_vert"></m-icon></span>
          ${childrens.length > 0 ? html`${childrens.map(child => this.renderTreeItems(child, items))}` : ''}
        </sl-tree-item>
      `
    }

    const displayChildrenCount = nodeType.displayChildrenCount;

    if (nodeType.displayOnlyIfHasChildren && !childrens.length) {
      return '';
    }

    if (typeof item.enabled === 'boolean' && !item.enabled) {
      item.css ? item.css += ' disabled' : item.css = 'disabled';
    }

    return html`
      <sl-tree-item 
        draggable="${item.nodeType !== 'root'}"
        data-id="${item._id}"
        data-name="${item.name}"
        .item="${item}"
        value="${this.getNodeTitle(item)}"
        class="${item.css || ''} ${item.nodeType} ${this.isNodeDisabled(item) ? 'disabled' : ''}"
        
        @contextmenu="${(ev) => this.showContextMenu(ev, item)}"
        @dragstart="${(ev) => this.dadOnDragStart(ev, item)}"
        @dragover="${(ev) => this.dadOnDragOver(ev, item)}"
        @drop="${(ev) => this.dadOnDrop(ev, item)}"
        @dblclick="${(ev) => this.showEditItem(ev)}"
        @sl-after-expand="${() => this.treeStoreExpanded()}"
        @sl-after-collapse="${() => this.treeStoreExpanded()}"
      >
        <span class="icons_left" title="${item.name} (${item.nodeType})">${this.renderTreeItemIcon(item)}</span>
        <span class="node_title" title="${item.name} (${item.nodeType})" class="${item.nodeType}">
          ${this.getNodeTitle(item)}
          ${displayChildrenCount ? html` (${childrens.length})` : ''}
        </span>
        <span class="icons_right">${this.renderIconsItem(item)}</span>
        <span class="tree_menu" @click="${ev => this.showTreeMenu(ev)}"><m-icon name="more_vert"></m-icon></span>
        ${childrens.length > 0 ? html`${childrens.map(child => this.renderTreeItems(child, items))}` : ''}
      </sl-tree-item>
    `;
  }

  renderTree() {
    this._log.debug('renderTree');
    if (this.loading) return;
    if (this.items?.length) {
      const rootItems = this.items ? this.items.filter(item => !item.parentId || !this.items.some(i => i._id === item.parentId)) : [];
      return html`
        <div style="position:relative;">
          <sl-tree selection="single" @sl-selection-change="${this.onTreeSelectionChange}">
            ${rootItems.map(item => this.renderTreeItems(item, this.items))}
          </sl-tree>
        </div>
      `
    } else {
      return html`<div>C'est vide !</div>`;
    }
  }

  render() {
    this._log.info('render', 'items', this.items?.length);
    return html`
      ${this.renderLoading()}
      ${this.renderTree()}
    `;
  }
}

customElements.define('select-registry', SelectRegistry);